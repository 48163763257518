<template id="app">
  <h1><router-link class="lienAccueil" to="/">Olivier Ebel</router-link></h1>
  <h2>Peinture & Mobilier contemporain</h2>
  <nav-bar />
  <router-view />
</template>

<script>
import NavBar from "./components/Navbar.vue";
export default {
  name: "App",
  components: {
    NavBar,
  },
};
</script>

<style>
@import "./css/variables.css";
body {
  background: var(--dysform-black);
  color: white;
}

.body-lock-scroll {
  height: 100%;
  overflow: hidden;
}

#app {
  font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue",
    "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background: var(--dysform-black);
  display: flex;
  flex-direction: column;
  align-items: center;
}

router-view {
  width: 100%;
}
h1 {
  font-weight: bold;
  font-size: 13rem;
  letter-spacing: -0.5rem;
  margin: 0.3rem 0rem;
}

h2 {
  font-weight: bold;
  font-size: 2.5rem;
  margin: 0.3rem 0rem;
}

h3 {
  font-weight: bold;
  font-size: 2rem;
}

h4 {
  font-weight: bold;
  font-size: 2rem;
}

div {
  display: flex;
  justify-content: center;
}

p {
  font-size: 22px;
  width: 60%;
  margin-bottom: 3rem;
  text-align: justify;
}

.lienAccueil {
  color: white;
}
.lienAccueil:hover {
  color: white;
  text-shadow: 0px 0px 10px white;
}

.infos {
  text-align: center;
  width: 60%;
}

.presentoir {
  width: 70%;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-content: flex-start;
}

.modal-background {
  z-index: 1;
  position: absolute;
  background: var(--dysform-black);
  width: 500%;
  height: 200%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 70%;
}
.modal {
  z-index: 2;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  position: absolute;
  background: white;
  width: 1500px;
  height: 800px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 0.5rem;
}
</style>
