<template>
  <nav>
    <router-link active-class="boutonActif" to="/">ACCUEIL</router-link>
    <router-link active-class="boutonActif" to="/fauteuils"
      >FAUTEUILS</router-link
    >
    <router-link active-class="boutonActif" to="/tabourets"
      >TABOURETS</router-link
    >
    <router-link active-class="boutonActif" to="/tables">TABLES</router-link>
    <router-link active-class="boutonActif" to="/peintures"
      >PEINTURES</router-link
    >
    <router-link active-class="boutonActif" to="/olivier">OLIVIER</router-link>
    <router-link active-class="boutonActif" to="/contact">CONTACT</router-link>
  </nav>
</template>

<script>
export default {
  name: "NavBar",
  data() {
    return {
      boutonActif: "",
    };
  },
};
</script>

<style>
nav {
  margin: 1% 0%;
  background: linear-gradient(
    to right,
    var(--transparent) 0% 5%,
    var(--dysform-orange) 20% 80%,
    var(--transparent) 95% 100%
  );
  width: 100%;
  padding: 0.3rem 0rem;
  font-weight: bold;
  font-size: 1.2rem;
}

a {
  margin: 1.5rem;
  padding: 2rem;
  text-decoration: none;
  color: white;
}

a:visited {
  color: white;
}

a:hover {
  color: var(--dysform-black);
}

.boutonActif {
  background: var(--dysform-black);
}
.boutonActif:hover {
  color: white;
}
</style>
