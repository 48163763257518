import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/PageAccueil.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/olivier',
    name: 'olivier',
    component: function () {
      return import('../views/PageOlivier.vue')
    }
  },
  {
    path: '/fauteuils',
    name: 'fauteuils',
    component: function () {
      return import('../views/PageFauteuils.vue')
    }
  },
  {
    path: '/tabourets',
    name: 'tabourets',
    component: function () {
      return import('../views/PageTabourets.vue')
    }
  },
  {
    path: '/tables',
    name: 'tables',
    component: function () {
      return import('../views/PageTables.vue')
    }
  },
  {
    path: '/peintures',
    name: 'peintures',
    component: function () {
      return import('../views/PagePeintures.vue')
    }
  },
  {
    path: '/contact',
    name: 'contact',
    component: function () {
      return import('../views/PageContact.vue')
    }
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
