<template>
  <h3>Exposition à Guérande - Août 2024</h3>
  <img class="" src="../img/expo_guerande.jpg" />
</template>

<script>
export default {
  name: "PageAccueil",
  components: {},
};
</script>

<style scoped>
li {
  list-style: none;
}

div {
  display: flex;
  justify-content: center;
}

.img-article {
  float: left;
  width: 30%;
}
</style>
